import { useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import Input from "./form/Input";
import Select from "./form/Select";
import TextArea from "./form/TextArea";
import Checkbox from "./form/Checkbox";
import Swal from "sweetalert2";

const EditPlayer = () => {
  const navigate = useNavigate();
  const { jwtToken } = useOutletContext();

  const [error, setError] = useState(null);
  const [errors, setErrors] = useState([]);

  const hasError = (key) => {
    return errors.indexOf(key) !== -1;
  };

  const [player, setPlayer] = useState({
    id: 0,
    first_name: "",
    last_name: "",
    age: 0,
    weight: 0,
    height: "",
    image: '',
    experience: '',
  });

  // get id from the URL
  let { id } = useParams();
  if (id === undefined) {
    id = 0;
  }

  useEffect(() => {
    if (jwtToken === "") {
      navigate("/login");
      return;
    }

    if (id === 0) {
      // adding a movie
      setPlayer({
        id: 0,
        first_name: "",
        last_name: "",
        age: 0,
        weight: 0,
        height: "",
        image: '',
        experience: '',
      });

      const headers = new Headers();
      headers.append("Content-Type", "application/json");

      const requestOptions = {
        method: "GET",
        headers: headers,
      };
    } else {
      // editing an existing movie
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + jwtToken);

      const requestOptions = {
        method: "GET",
        headers: headers,
      };

      fetch(`${process.env.REACT_APP_BACKEND}/admin/players/${id}`, requestOptions)
        .then((response) => {
          if (response.status !== 200) {
            setError("Invalid response code: " + response.status);
          }
          return response.json();
        })
        .then((data) => {
          console.log(data)
          // set state
          setPlayer({
            ...data.player,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [id, jwtToken, navigate]);

  const handleSubmit = (event) => {
    event.preventDefault();

    let errors = [];
    let required = [
      { field: player.first_name, name: "first_name" },
      { field: player.last_name, name: "last_name" },
      { field: player.age, name: "age" },
      { field: player.weight, name: "weight" },
      { field: player.height, name: "height" },
      { field: player.experience, name: "experience" },
    ];

    required.forEach(function (obj) {
      if (obj.field === "") {
        errors.push(obj.name);
      }
    });


    setErrors(errors);

    if (errors.length > 0) {
      return false;
    }

    // passed validation, so save changes
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + jwtToken);

    // assume we are adding a new movie
    let method = "PUT";

    if (player.id > 0) {
      method = "PATCH";
    }

    const requestBody = player;
    // we need to covert the values in JSON for release date (to date)
    // and for runtime to int
    let requestOptions = {
      body: JSON.stringify(requestBody),
      method: method,
      headers: headers,
      credentials: "include",
    };

    fetch(`${process.env.REACT_APP_BACKEND}/admin/players/${player.id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          console.log(data.error);
        } else {
          navigate("/manage-player");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = () => (event) => {
    let value = event.target.value;
    let name = event.target.name;
    setPlayer({
      ...player,
      [name]: value,
    });
  };


  const confirmDelete = () => {
    Swal.fire({
        title: 'Delete player?',
        text: "You cannot undo this action!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
            let headers = new Headers();
            headers.append("Authorization", "Bearer " + jwtToken)

            const requestOptions = {
                method: "DELETE",
                headers: headers,
            }

          fetch(`${process.env.REACT_APP_BACKEND}/admin/players/${player.id}`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if (data.error) {
                    console.log(data.error);
                } else {
                    navigate("/manage-player");
                }
            })
            .catch(err => {console.log(err)});
        }
      })
  }

  if (error !== null) {
    return <div>Error: {error.message}</div>;
  } else {
    return (
      <div>
        <h2>Add/Edit Player</h2>
        <hr />
        {/* <pre>{JSON.stringify(movie, null, 3)}</pre> */}

        <form onSubmit={handleSubmit}>
          <input type="hidden" name="id" value={player.id} id="id"></input>

          <Input
            title={"First Name"}
            className={"form-control"}
            type={"text"}
            name={"first_name"}
            value={player.first_name}
            onChange={handleChange("first_name")}
            errorDiv={hasError("first_name") ? "text-danger" : "d-none"}
            errorMsg={"Please enter a first name"}
          />

          <Input
            title={"Last Name"}
            className={"form-control"}
            type={"text"}
            name={"last_name"}
            value={player.last_name}
            onChange={handleChange("last_name")}
            errorDiv={hasError("last_name") ? "text-danger" : "d-none"}
            errorMsg={"Please enter a last name"}
          />

          <Input
            title={"age"}
            className={"form-control"}
            type={"number"}
            name={"age"}
            value={player.age}
            onChange={handleChange("age")}
            errorDiv={hasError("age") ? "text-danger" : "d-none"}
            errorMsg={"Please enter a age"}
          />
            <Input
            title={"weight"}
            className={"form-control"}
            type={"number"}
            name={"weight"}
            value={player.age}
            onChange={handleChange("weight")}
            errorDiv={hasError("weight") ? "text-danger" : "d-none"}
            errorMsg={"Please enter a weight"}
          />
               <Input
            title={"height"}
            className={"form-control"}
            type={"number"}
            name={"height"}
            value={player.age}
            onChange={handleChange("height")}
            errorDiv={hasError("height") ? "text-danger" : "d-none"}
            errorMsg={"Please enter a height"}
          />

          <Input
            title={"Experience"}
            className={"form-control"}
            type={"text"}
            name={"experience"}
            value={player.Experience}
            onChange={handleChange("experience")}
            errorDiv={hasError("experience") ? "text-danger" : "d-none"}
            errorMsg={"Please enter an experience level"}
           />
  
  
          <hr />


          <hr />

          <button className="btn btn-primary">Save</button>

          {player.id > 0 && (
            <a href="#!" className="btn btn-danger ms-2" onClick={confirmDelete}>
              Delete Player
            </a>
          )}
        </form>
      </div>
    );
  }
};

export default EditPlayer;
