import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import App from './App';
import EditPlayer from './components/EditPlayer';
import ErrorPage from './components/ErrorPage';
import Genres from './components/Genres';
import GraphQL from './components/GraphQL';
import Home from './components/Home';
import Login from './components/Login';
import ManagePlayers from './components/ManagePlayers';
import Players from './components/Players';
import Player from './components/Player';
import OneGenre from './components/OneGenre';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {index: true, element: <Home /> },
      {
        path: "/players",
        element: <Players />,
      },
      {
        path: "/players/:id",
        element: <Player />,
      },
      {
        path: "/admin/player/0",
        element: <EditPlayer />,
      },
      {
        path: "/admin/player/:id",
        element: <EditPlayer />,
      },
      {
        path: "/manage-player",
        element: <ManagePlayers />,
      },
      {
        path: "/graphql",
        element: <GraphQL />,
      },
      {
        path: "/login",
        element: <Login />,
      },
    ]
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
