import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const Player = () => {
    const [player, setPlayer] = useState({});
    let { id } = useParams();

    useEffect(() => {
        const headers = new Headers();
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: "GET",
            headers: headers,
        }

        fetch(`${process.env.REACT_APP_BACKEND}/players/${id}`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setPlayer(data);
            })
            .catch(err => {
                console.log(err);
            })
    }, [id])

    return(
        <div>
            <h2>Player: {player.first_name} {player.last_name}</h2>
            <small><em>{player.age}, {player.height}, Rated {player.age}</em></small><br />
            <hr />

            {player.image !== "" &&
                <div className="mb-3">
                    <img src={player.image} alt="poster" />
                </div>
            }

            <p>IDK</p>
        </div>
    )
}

export default Player;