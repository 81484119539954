import { Link } from "react-router-dom";
import Nba from "./../images/nba.png";

const Home = () => {
  return (
    <>
      <div className="text-center">
        <h2>Lift SM Portal!</h2>
        <hr />
        <Link to="/players">
          <img src={Nba} alt="movie tickets"></img>
        </Link>
      </div>
    </>
  );
};

export default Home;
