import { useEffect, useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";

const ManagePlayers = () => {
    const [players, setPlayers] = useState([]);
    const { jwtToken } = useOutletContext();
    const navigate = useNavigate();

    useEffect( () => {
        if (jwtToken === "") {
            navigate("/login");
            return
        }
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", "Bearer " + jwtToken);

        const requestOptions = {
            method: "GET",
            headers: headers,
        }

        fetch(`${process.env.REACT_APP_BACKEND}/admin/players`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setPlayers(data);
            })
            .catch(err => {
                console.log(err);
            })

    }, [jwtToken, navigate]);

    return(
        <div>
        <h2>Manage Players</h2>
        <hr />
        <table className="table table-striped table-hover">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Release Date</th>
                    <th>Rating</th>
                </tr>
            </thead>
            <tbody>
                {players.map((m) => (
                       <tr key={m.id}>
                       <td>
                           <Link to={`/admin/player/${m.id}`}>
                               {m.first_name} {m.last_name}
                           </Link>
                       </td>
                       <td>{m.age}</td>
                       <td>{m.weight}</td>
                   </tr>     
                ))}
            </tbody>
        </table>
    </div>
    )
}

export default ManagePlayers;