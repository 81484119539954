import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
const Players = () => {
    const [movies, setMovies] = useState([]);

    useEffect( () => {
        const headers = new Headers();
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: "GET",
            headers: headers,
        }

        fetch(`${process.env.REACT_APP_BACKEND}/players`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setMovies(data);
            })
            .catch(err => {
                console.log(err);
            })

    }, []);

    return(
        <div>
            <h2>NBA Players</h2>
            <hr />

            {movies.map((m) => (
                <Link key={m.id} to={`/players/${m.id}`}>
            <Card  style={{ width: '18rem' }}>
                <Card.Img variant="top" src={`${m.image}`} />
                <Card.Body>
                    <Card.Title>{m.first_name} {m.last_name}</Card.Title>
                    <Card.Text>
                    
                    </Card.Text>
                </Card.Body>
            </Card>
            </Link>
               ))}
           
        </div>
    )
}

export default Players;